import React, { Component } from "react";
import "../styles/Logo.scss";

class Logo extends Component {
  render() {
    return (
      <div className="logo">
        <div className="logo--half">
          <div className="toggle">
            <div className="toggle--half">
              <div className="square" />
            </div>
          </div>
        </div>
        <div className="logo--half">
          <div className="toggle">
            <div className="toggle--half">
              <div className="square" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Logo;
