import React from "react";
import { injectIntl } from "react-intl";

const Search = ({ intl, setSearch }) => {
  return (
    <form onSubmit={e => e.preventDefault()}>
      <div className="agreement-box agreement-box--search">
        <div className="search-container">
          <input
            className="search-field"
            type="text"
            placeholder={intl.formatMessage({ id: "search" })}
            autoFocus
            onChange={e => setSearch(e.target.value)}
          />
        </div>
      </div>
    </form>
  );
};

export default injectIntl(Search);
