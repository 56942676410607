import React from "react";
import Toggle from "./Toggle";
import { database } from "./Firebase";
import Textarea from "react-textarea-autosize";
import { FormattedMessage, injectIntl } from "react-intl";

class ParagraphContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      togglesStatus: "none",
    };
  }

  getTogglesStatus = () => {
    database
      .ref(
        "agreementList/" +
          this.props.agreementId +
          "/chapter/" +
          this.props.chapterDatabaseId +
          "/paragraph/" +
          this.props.paragraphDatabaseId +
          "/uid/"
      )
      .on("value", (snapshot) => {
        let listOfToggles = [];
        let togglesStatus;

        snapshot.forEach((childSnapshot) => {
          listOfToggles.push(childSnapshot.val().toggle);
        });

        if (listOfToggles.length == 0 || !listOfToggles.includes(true))
          togglesStatus = "none";
        if (listOfToggles.includes(true)) togglesStatus = "thereExistToggled";
        if (!listOfToggles.includes(false) && listOfToggles.length > 1)
          togglesStatus = "allIsToggled";

        this.setState({ togglesStatus });
      });
  };

  componentDidMount = async () => {
    this.getTogglesStatus();
  };

  render() {
    const toggles = [];
    const { intl } = this.props;

    Object.keys(this.props.users).forEach((user) => {
      toggles.push(
        <Toggle
          key={user}
          userWhoCanToggleThis={user}
          uid={this.props.uid}
          paragraphIndex={this.props.paragraphIndex}
          chapterIndex={this.props.chapterIndex}
          agreementId={this.props.agreementId}
          chapterDatabaseId={this.props.chapterDatabaseId}
          paragraphDatabaseId={this.props.paragraphDatabaseId}
          displayName={this.props.displayName}
          togglesStatus={this.state.togglesStatus}
          users={this.props.users}
          handleSubmit={this.props.handleSubmit}
          saveParagraphContent={this.props.saveParagraphContent}
        />
      );
    });

    return (
      <div className={this.props.shouldEdit ? "paragraph-content" : ""}>
        <form
          onSubmit={(event) =>
            this.props.handleSubmit(this.props.paragraphIndex, event)
          }
        >
          <label>
            <Textarea
              className="paragraph-content__textarea"
              value={this.props.value}
              onChange={(event) =>
                this.props.handleChange(this.props.paragraphIndex, event)
              }
              readOnly={
                this.state.togglesStatus == "thereExistToggled" ||
                this.state.togglesStatus == "allIsToggled"
              }
              autoFocus={this.state.togglesStatus == "none"}
            />
          </label>
          <input
            className="button "
            type="submit"
            value={intl.formatMessage({ id: "save-and-close" })}
          />
          {this.state.togglesStatus == "none" && (
            <button
              className="button"
              onClick={(event) =>
                this.props.onRemoveParagraph(
                  this.props.chapterIndex + 1,
                  this.props.paragraphIndex,
                  event
                )
              }
            >
              <FormattedMessage id="delete" />
            </button>
          )}
        </form>
        <div className="toggles">{toggles}</div>
      </div>
    );
  }
}

export default injectIntl(ParagraphContent);
