import React, { Component } from "react";
import { Link } from "react-router-dom";

class AgreementListItem extends Component {
  render() {
    return (
      <Link to="/agreement">
        <button
          className="agreement-list__item"
          onClick={() => this.props.onClick(this.props.index)}
        >
          {this.props.icon}
          <span>{this.props.title}</span>
        </button>
      </Link>
    );
  }
}

export default AgreementListItem;
