import React, { Component } from "react";
import { Route, Link } from "react-router-dom";
import Agreement from "./Agreement";
import { database } from "./Firebase";
import MyAccount from "./MyAccount";
import Template from "./Template";
import Templates from "./Templates";
import Title from "./Title";
import TopNavbar from "./TopNavbar";
import AgreementList from "./AgreementList";
import Feedback from "./Feedback";

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      agreementList: [],
      templateList: [],
      currentTemplate: null,
      currentAgreement: null,
      showFeedback: false
    };
  }

  createAgreement = newAgreement => {
    const maxAgreements = 50;
    if (this.state.agreementList.length >= maxAgreements) {
      window.alert(`Du kan högst ha ${maxAgreements} avtal.`);
    } else database.ref("agreementList").push(newAgreement);
  };

  renderAgreement = clickedAgreement => {
    this.setState({
      currentAgreement: clickedAgreement
    });
  };

  renderTemplate = clickedTemplate => {
    this.setState({
      currentTemplate: clickedTemplate
    });
  };

  readAgreementsFromDatabase = () => {
    database
      .ref("agreementList/")
      .orderByChild(`users/${this.props.uid}/email`)
      .equalTo(this.props.user.email)
      .on("value", snapshot => {
        const agreements = [];

        snapshot.forEach(childSnapshot => {
          agreements.push({
            agreementId: childSnapshot.key,
            ...childSnapshot.val()
          });
        });
        this.setState({ agreementList: agreements });
      });
  };

  readTemplatesFromDatabase = () => {
    database
      .ref("templateList/")
      .once("value")
      .then(snapshot => {
        const agreements = [];
        snapshot.forEach(childSnapshot => {
          agreements.push({
            agreementId: childSnapshot.key,
            ...childSnapshot.val()
          });
        });
        this.setState({ templateList: agreements });
      });
  };

  deleteAgreement = agreementIdFromMyAccount => {
    const clone = [...this.state.agreementList];
    clone.splice(this.state.currentAgreement, 1);

    if (agreementIdFromMyAccount) {
      database
        .ref("agreementList/" + agreementIdFromMyAccount)
        .remove()
        .then(
          this.setState({
            agreementList: clone
          })
        );
    } else {
      database
        .ref(
          "agreementList/" +
            this.state.agreementList[this.state.currentAgreement].agreementId
        )
        .remove()
        .then(
          this.setState({
            agreementList: clone,
            currentAgreement: null
          })
        );
    }
  };

  writeUserToDatabase = async () => {
    let hasSetName = false;
    let firstTimeLogin;

    await database
      .ref("users/" + this.props.user.uid)
      .once("value")
      .then(snapshot => {
        hasSetName = snapshot.val() ? snapshot.val().name : false;
        firstTimeLogin = !snapshot.val();
      });

    if (!hasSetName) {
      database.ref("users/" + this.props.user.uid).update({
        email: this.props.user.email,
        displayName: this.props.user.displayName,
        name: this.props.user.displayName
      });
    }

    if (firstTimeLogin) {
      this.createAgreement({
        title: "First agreement!",
        users: await this.getUser()
      });
    }
  };

  getDateTime = () => {
    const today = new Date();
    const date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
    const time =
      today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    return date + " " + time;
  };

  componentDidMount() {
    this.readAgreementsFromDatabase();
    this.readTemplatesFromDatabase();
    this.writeUserToDatabase();
  }

  copyAgreement = async currentTemplate => {
    const template = this.state.templateList[currentTemplate];
    delete template.agreementId;
    template.users = await this.getUser();

    database.ref("agreementList").push(template);
  };

  changeAgreementTitle = event => {
    const agreementList = [...this.state.agreementList];
    agreementList[this.state.currentAgreement].title = event.target.value;
    this.setState({ agreementList });
  };

  submitAgreementTitle = event => {
    event.preventDefault();

    database
      .ref(
        "agreementList/" +
          this.state.agreementList[this.state.currentAgreement].agreementId
      )
      .update({
        title: this.state.agreementList[this.state.currentAgreement].title
      }); // bör göra det till en controlled component istället för att skicka event, ta value från state
    /* .then(this.setState({ agreementList: spara rätt titel i rätt agreement? })); */
  };

  allHasSigned = agreement => {
    return agreement.hasSigned
      ? Object.keys(agreement.hasSigned).length >= 2
      : false;
  };

  getUser = async () => {
    let user;
    let name;
    let personalNumber;
    let email;

    await database.ref(`users/${this.props.uid}`).once("value", snapshot => {
      user = snapshot.val();
      name = user.name || user.displayName;
      personalNumber = user.personalNumber || null;
      email = user.email || null;
    });
    const users = {};
    users[this.props.uid] = { name, personalNumber, email };
    return users;
  };

  toggleFeedback = () => {
    this.setState({
      showFeedback: this.state.showFeedback ? false : true
    });
  };

  render() {
    return (
      <>
        <Route
          path="/home"
          render={props => (
            <div className="start-screen screen-holder">
              <TopNavbar />
              <Title />
              <AgreementList
                agreementList={this.state.agreementList}
                onClick={this.renderAgreement}
                allHasSigned={this.allHasSigned}
              />

              <Link to="/create-agreement">
                <button className="button button--add-new button--bottom-right button--circle">
                  <svg
                    className="plus-sign"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path d="M416 277.333H277.333V416h-42.666V277.333H96v-42.666h138.667V96h42.666v138.667H416v42.666z" />
                  </svg>
                </button>
              </Link>
              <div className="feedback-wrapper">
                <div
                  className="show-feedback"
                  onClick={() => {
                    this.toggleFeedback();
                  }}
                >
                  Tyck till
                </div>

                <Feedback
                  getDateTime={this.getDateTime}
                  showFeedback={this.state.showFeedback}
                  toggleFeedback={this.toggleFeedback}
                  user={this.props.user}
                />
              </div>
            </div>
          )}
        />

        <Route
          path="/agreement"
          render={props => (
            <Agreement
              agreement={this.state.agreementList[this.state.currentAgreement]}
              uid={this.props.uid}
              email={this.props.email}
              deleteAgreement={this.deleteAgreement}
              displayName={this.props.displayName}
              changeAgreementTitle={this.changeAgreementTitle}
              submitAgreementTitle={this.submitAgreementTitle}
              invite={this.invite}
              allHasSigned={this.allHasSigned}
            />
          )}
        />

        <Route
          path="/account"
          render={() => (
            <MyAccount
              uid={this.props.uid}
              displayName={this.props.displayName}
              user={this.props.user}
              deleteAgreement={this.deleteAgreement}
              logout={this.props.logout}
              allHasSigned={this.allHasSigned}
            />
          )}
        />

        <Route
          path="/create-agreement"
          render={props => (
            <Templates
              {...props}
              renderTemplate={this.renderTemplate}
              templateList={this.state.templateList}
              createAgreement={this.createAgreement}
              uid={this.props.uid}
              getUser={this.getUser}
            />
          )}
        />

        <Route
          path="/template"
          render={props => (
            <Template
              renderTemplate={this.renderTemplate}
              showTemplates={this.showTemplates}
              agreementTitle={
                this.state.templateList[this.state.currentTemplate].title
              }
              agreementId={
                this.state.templateList[this.state.currentTemplate].agreementId
              }
              uid={this.props.uid}
              email={this.props.email}
              users={[this.props.user]}
              deleteAgreement={this.deleteTemplate}
              displayName={this.props.displayName}
              copyAgreement={this.copyAgreement}
              currentTemplate={this.state.currentTemplate}
            />
          )}
        />
      </>
    );
  }
}

export default Home;
