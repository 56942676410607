import React, { Component } from "react";

export default class SignatureData extends Component {
  render() {
    return (
      <>
        {this.props.personalData[this.props.part].name}
        {this.props.signatures && this.props.signatures[this.props.part]
          ? ", " + this.props.signatures[this.props.part].date
          : ", " + this.props.getTodaysDate()}
      </>
    );
  }
}
