import React, { Component } from "react";
import Title from "./Title";
import Logo from "./Logo";
import { Route, Link } from "react-router-dom";
import Conditions from "./Conditions";
import { FormattedMessage } from "react-intl";

class Login extends Component {
  render() {
    return (
      <>
        <Route
          exact
          path="/login"
          render={() => (
            <div className="start-screen screen-holder" id="start-screen-login">
              <Title />
              {/* <img className="both-like" src="both_like.png" alt="both like" /> */}
              <Logo />
              <div className="google-button" onClick={this.props.onLogin}>
                <img
                  className="google-logo"
                  src="btn_google_dark_normal_ios.svg"
                  alt="Sign in with Google"
                />
                <span className="google-button__content">
                  <FormattedMessage id="google" />
                </span>
              </div>
              <p className="terms">
                <FormattedMessage id="by-signing-in" />
                <Link to="/login/terms">
                  <FormattedMessage id="terms" />
                </Link>
                .
              </p>
            </div>
          )}
        />
        <Route
          path="/login/terms"
          render={() => (
            <Conditions heading={<FormattedMessage id="terms" />} />
          )}
        />
      </>
    );
  }
}

export default Login;
