import React, { Component } from "react";
import AgreementListItem from "./AgreementListItem";
import { FormattedMessage } from "react-intl";

export default class AgreementList extends Component {
  constructor(props) {
    super(props);
    this.state = { thereExistAgreementAllHasSigned: false };
  }

  checkIfthereExistAgreementAllHasSigned = () => {
    const thereExist = Object.values(this.props.agreementList).some(
      agreement => {
        const allHasSigned = agreement.hasSigned
          ? Object.keys(agreement.hasSigned).length === 2
          : false;
        return allHasSigned;
      }
    );

    if (thereExist) {
      this.setState({ thereExistAgreementAllHasSigned: true });
    }
  };

  componentDidUpdate(prevProps) {
    //Denna behövs eftersom när componentDidMount körs så har agreementList inte hunnit hämtats i home
    if (this.props.agreementList.length !== prevProps.agreementList.length) {
      this.checkIfthereExistAgreementAllHasSigned();
    }
  }

  componentDidMount() {
    this.checkIfthereExistAgreementAllHasSigned();
  }

  render() {
    return (
      <div className="agreement-list">
        <div className="no-consent">
          {this.state.thereExistAgreementAllHasSigned && (
            <h3 className="agreement-list__heading">
              <FormattedMessage id="draft" />
            </h3>
          )}
          {this.props.agreementList.map((agreement, i) => {
            const allHasSigned = this.props.allHasSigned(agreement);

            if (!allHasSigned) {
              return (
                <AgreementListItem
                  key={this.props.agreementList[i].agreementId}
                  title={this.props.agreementList[i].title}
                  onClick={this.props.onClick}
                  index={i}
                  icon={
                    <svg
                      className="pen"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path d="M64 368v80h80l235.727-235.729-79.999-79.998L64 368zm377.602-217.602c8.531-8.531 8.531-21.334 0-29.865l-50.135-50.135c-8.531-8.531-21.334-8.531-29.865 0l-39.468 39.469 79.999 79.998 39.469-39.467z" />
                    </svg>
                  }
                />
              );
            }
          })}
        </div>
        <div className="consent">
          {this.state.thereExistAgreementAllHasSigned && (
            <h3 className="agreement-list__heading">
              <FormattedMessage id="agreed" />
            </h3>
          )}
          {this.props.agreementList.map((agreement, i) => {
            const allHasSigned = this.props.allHasSigned(agreement);

            if (allHasSigned) {
              return (
                <AgreementListItem
                  key={this.props.agreementList[i].agreementId}
                  title={this.props.agreementList[i].title}
                  onClick={this.props.onClick}
                  index={i}
                  icon={<i className="far fa-handshake" />}
                />
              );
            }
          })}
        </div>
      </div>
    );
  }
}
