import React, { Component } from "react";
import { Link } from "react-router-dom";

class TemplateListItem extends Component {
  render() {
    return (
      <Link to="/template">
        <button
          className="agreement-list__item"
          onClick={() => this.props.onClick(this.props.index)}
        >
          {this.props.title}
        </button>
      </Link>
    );
  }
}

export default TemplateListItem;
