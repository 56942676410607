import React, { Component } from "react";
import TemplateChapter from "./TemplateChapter";
import { database } from "./Firebase";
import BackButton from "./BackButton";
import Search from "./Search";
import { Link } from "react-router-dom";
import { injectIntl, FormattedMessage } from "react-intl";

class Template extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numChapters: 0,
      chapters: [],
      showInvite: false,
      sharedAgreement: null,
      users: this.props.users,
      uidToInvite: null,
      signed: false,
      search: "",
      showSearchBar: false,
      name: [],
      personalNumber: []
    };
  }

  readChaptersFromDatabase = () => {
    database
      .ref("templateList/" + this.props.agreementId + "/chapter/")
      .once("value")
      .then(snapshot => {
        const chapters = [];

        snapshot.forEach(childSnapshot => {
          chapters.push({
            chapterDatabaseId: childSnapshot.key,
            ...childSnapshot.val()
          });
        });
        this.setState({ chapters: chapters }, () => {});
      });
  };

  componentWillMount() {
    // Onödig funktion om det ändå sätts direkt i state i konstruktorn?
    this.setState({ users: this.props.users });
  }

  componentDidMount() {
    this.readChaptersFromDatabase();
  }

  setSearch = search => {
    this.setState(() => ({
      search
    }));
  };

  filterChapters = () => {
    return this.state.chapters.filter(chapter =>
      chapter.title.includes(this.state.search)
    );
  };

  showSearchBar = () => {
    this.setState({ showSearchBar: this.state.showSearchBar ? false : true });
  };

  render() {
    const { intl } = this.props;

    return (
      <div className="background screen-holder">
        <div className="top-navbar">
          <BackButton />

          <h2
            className="template-title top-navbar__title"
            onClick={() =>
              console.log(
                "kör en funktion som ändrar en bolean så denna går att ändra"
              )
            }
          >
            {this.props.agreementTitle}
          </h2>
          <button className="magnifier" onClick={this.showSearchBar}>
            <i className="fas fa-search" />
          </button>
        </div>

        {this.state.showSearchBar && <Search setSearch={this.setSearch} />}

        <div className="agreement-content">
          {this.state.chapters.map((p, i) => (
            <TemplateChapter
              key={this.state.chapters[i].chapterDatabaseId}
              chapterIndex={i}
              agreementId={this.props.agreementId}
              uid={this.props.uid}
              chapters={this.state.chapters}
              users={this.state.users}
              chapterDatabaseId={this.state.chapters[i].chapterDatabaseId}
              displayName={this.props.displayName}
              signed={this.state.signed}
              search={this.state.search}
            />
          ))}
          <Link to="/home">
            <button
              className="button button--large"
              onClick={() =>
                this.props.copyAgreement(this.props.currentTemplate)
              }
            >
              <FormattedMessage id="copy-agreement" />
            </button>
          </Link>
        </div>
      </div>
    );
  }
}

export default injectIntl(Template);
