import React, { Component } from "react";
import Paragraph from "./Paragraph";
import { database } from "./Firebase";
import Textarea from "react-textarea-autosize";
import { FormattedMessage, injectIntl } from "react-intl";
import OutsideClickHandler from "react-outside-click-handler";

class Chapter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paragraphList: [],
      edit: [],
      thereExistAgreedParagraph: false
    };
  }

  handleChange = (paragraphIndex, event) => {
    var paragraphList2 = [...this.state.paragraphList];
    paragraphList2[paragraphIndex].value = event.target.value;

    this.setState({ paragraphList: paragraphList2 });
  };

  readParagraphsFromDatabase = () => {
    database
      .ref(
        "agreementList/" +
          this.props.agreementId +
          "/chapter/" +
          this.props.chapterDatabaseId +
          "/paragraph/"
      )
      .on("value", async snapshot => {
        const paragraphList = [];

        snapshot.forEach(childSnapshot => {
          paragraphList.push({
            paragraphDatabaseId: childSnapshot.key,
            ...childSnapshot.val()
          });
        });
        await this.setState({ paragraphList });
        this.checkIfThereExistAgreedParagraph();
      });
  };

  async componentDidMount() {
    await this.readParagraphsFromDatabase();
    this.checkIfThereExistAgreedParagraph();
    this.setState({
      edit: new Array(this.state.paragraphList.length).fill(false)
    });
  }

  handleSubmit = async (paragraphIndex, event) => {
    event.preventDefault();

    await this.saveParagraphContent(paragraphIndex);
    this.closeParagraphContent(paragraphIndex);
  };

  saveParagraphContent = paragraphIndex => {
    const paragraphList = [...this.state.paragraphList];
    const paragraph = { ...paragraphList[paragraphIndex] };

    database
      .ref(
        "agreementList/" +
          this.props.agreementId +
          "/chapter/" +
          this.props.chapterDatabaseId +
          "/paragraph/" +
          this.state.paragraphList[paragraphIndex].paragraphDatabaseId
      )
      .update(paragraph);
  };

  closeParagraphContent = paragraphIndex => {
    const edit = [...this.state.edit];
    edit[paragraphIndex] = false;
    this.setState({ edit });
  };

  clickOnParagraph = paragraphIndex => {
    const edit = [...this.state.edit];
    edit[paragraphIndex] = true;
    this.setState({ edit });
  };

  onAddParagraph = newParagraph => {
    const maxParagraphs = 100;

    if (this.state.paragraphList.length >= maxParagraphs) {
      window.alert(
        `Du kan högst ha ${maxParagraphs} paragrafer i ett kapitel.`
      );
    } else {
      database
        .ref(
          "agreementList/" +
            this.props.agreementId +
            "/chapter/" +
            this.props.chapterDatabaseId +
            "/paragraph/"
        )
        .push(newParagraph);
    }
  };

  onRemoveParagraph = (chapterIndex, paragraphIndex) => {
    this.closeParagraphContent(paragraphIndex);
    var clone = [...this.state.paragraphList];
    clone.splice(paragraphIndex, 1);

    database
      .ref(
        "agreementList/" +
          this.props.agreementId +
          "/chapter/" +
          this.props.chapterDatabaseId +
          "/paragraph/" +
          this.state.paragraphList[paragraphIndex].paragraphDatabaseId
      )
      .remove()
      .then(
        this.setState({
          paragraphList: clone
        })
      )
      .catch(e => {
        window.alert("Something went wrong, try again.");
      });
  };

  checkIfThereExistAgreedParagraph = () => {
    let someParagraphIsAgreed = false;

    someParagraphIsAgreed = this.state.paragraphList.some(paragraph => {
      let paragraphIsAgreed = false;
      paragraphIsAgreed = paragraph.uid
        ? Object.keys(paragraph.uid).length >= 2
        : false;
      return paragraphIsAgreed;
    });

    if (someParagraphIsAgreed)
      this.setState({
        thereExistAgreedParagraph: true
      });
  };

  render() {
    const { intl } = this.props;

    return (
      <div className="agreement-box agreement-box--chapter">
        <div className="chapter-header chapter-header--chapter">
          <span className="chapter-index">{this.props.chapterIndex + 1}.</span>

          <form
            className="chapter-title"
            onSubmit={event =>
              this.props.submitTitle(this.props.chapterIndex, event)
            }
          >
            <OutsideClickHandler
              disabled={!this.props.chapters[this.props.chapterIndex].edit}
              onOutsideClick={event => {
                this.props.submitTitle(this.props.chapterIndex, event);
              }}
            >
              <label className="chapter-title--label">
                <Textarea
                  placeholder={intl.formatMessage({ id: "title" })}
                  value={this.props.chapters[this.props.chapterIndex].title}
                  onChange={event =>
                    this.props.changeTitle(this.props.chapterIndex, event)
                  }
                  className={
                    "chapter-title__textarea" +
                    (this.props.chapters[this.props.chapterIndex].edit
                      ? ""
                      : " editFalse")
                  }
                  onClick={
                    this.props.someoneHasSignedOrLocked
                      ? null
                      : () => this.props.editChapter(this.props.chapterIndex)
                  }
                  readOnly={this.props.someoneHasSignedOrLocked}
                  maxLength={120}
                  onKeyDown={event => {
                    this.props.handleEnterChapterTitle(
                      this.props.chapterIndex,
                      event
                    );
                  }}
                />
              </label>
            </OutsideClickHandler>
          </form>
          {!this.state.thereExistAgreedParagraph && (
            <button
              className="remove-chapter"
              onClick={() => this.props.removeChapter(this.props.chapterIndex)}
            >
              <i className="fas fa-trash-alt" />
            </button>
          )}
        </div>
        {this.state.paragraphList.map((paragraph, i) => (
          <Paragraph
            key={paragraph.paragraphDatabaseId}
            value={paragraph.value}
            edit={this.state.edit[i]}
            paragraphIndex={i}
            chapterIndex={this.props.chapterIndex}
            onRemoveParagraph={this.onRemoveParagraph}
            clickOnParagraph={this.clickOnParagraph}
            handleChange={this.handleChange}
            handleSubmit={this.handleSubmit}
            users={this.props.users}
            uid={this.props.uid}
            agreementId={this.props.agreementId}
            chapterDatabaseId={this.props.chapterDatabaseId}
            paragraphDatabaseId={paragraph.paragraphDatabaseId}
            displayName={this.props.displayName}
            someoneHasSignedOrLocked={this.props.someoneHasSignedOrLocked}
            otherUser={this.props.otherUser}
            saveParagraphContent={this.saveParagraphContent}
            isVisible={paragraph.value
              .toLowerCase()
              .includes(this.props.search.toLowerCase())}
          />
        ))}
        {!this.props.someoneHasSignedOrLocked && (
          <div>
            <button
              className="button button--add-new button--add-new-paragraph"
              onClick={() => this.onAddParagraph({ value: "" })}
            >
              <FormattedMessage id="new-paragraph" />
            </button>
          </div>
        )}
      </div>
    );
  }
}

export default injectIntl(Chapter);
