import React, { Component } from "react";
import Chapter from "./Chapter";
import { database } from "./Firebase";
import Dropdown from "./Dropdown";
import Sign from "./Sign";
import BackButton from "./BackButton";
import Parties from "./Parties";
import { FormattedMessage, injectIntl } from "react-intl";
import OutsideClickHandler from "react-outside-click-handler";
import Search from "./Search";

class Agreement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chapters: [],
      showInvite: false,
      someoneHasSigned: false,
      search: "",
      showSearchBar: false,
      personalData: {},
      editTitle: false,
      mode: "open",
      someoneHasSignedOrLocked: false,
      requestUnlockAgreementConfirmation: "",
      thereExistParagraph: false
    };
  }

  onAddChapter = newChapter => {
    const maxChapters = 50;
    if (this.state.chapters.length >= maxChapters) {
      window.alert(`Du kan högst ha ${maxChapters} kapitel.`);
    } else {
      database
        .ref("agreementList/" + this.props.agreement.agreementId + "/chapter/")
        .push(newChapter)
        .then(this.getChapterIdFromDatabase());
      this.setState({
        //behövs egentligen inte pga firebase on() lyssnar
        chapters: [...this.state.chapters, newChapter]
      });
    }
  };

  removeChapter = chapterIndex => {
    const clone = [...this.state.chapters];
    clone.splice(chapterIndex, 1);

    database
      .ref(
        "agreementList/" +
          this.props.agreement.agreementId +
          "/chapter/" +
          this.state.chapters[chapterIndex].chapterDatabaseId
      )
      .remove()
      .then(this.setState({ chapters: clone }));
  };

  changeTitle = (chapterIndex, event) => {
    const chapters = [...this.state.chapters];
    chapters[chapterIndex].title = event.target.value;
    this.setState({ chapters });
  };

  submitTitle = (chapterIndex, event) => {
    event.preventDefault();

    const chapters = [...this.state.chapters];
    chapters[chapterIndex].edit = false;

    database
      .ref(
        "agreementList/" +
          this.props.agreement.agreementId +
          "/chapter/" +
          this.state.chapters[chapterIndex].chapterDatabaseId
      )
      .update({ title: chapters[chapterIndex].title, edit: false })
      .then(this.setState({ chapters }));
  };

  handleEnterChapterTitle = (chapterIndex, event) => {
    if (event.key === "Enter") {
      this.submitTitle(chapterIndex, event);
      event.target.blur();
    }
  };

  editChapter = chapterIndex => {
    if (!this.state.someoneHasSigned) {
      const chapters = [...this.state.chapters];
      chapters[chapterIndex].edit = true;
      this.setState({ chapters });
    }
  };

  showInvite = () => {
    const showInvite = this.state.showInvite ? false : true;
    this.setState({ showInvite });
  };

  getUserFromEmail = async email => {
    let uid = "";
    let name = "";
    let personalNumber = "";
    const userData = {};

    await database
      .ref("users/")
      .once("value")
      .then(snapshot => {
        snapshot.forEach(childSnapshot => {
          if (childSnapshot.val().email.toLowerCase() === email.toLowerCase()) {
            uid = childSnapshot.key;
            name = childSnapshot.val().name
              ? childSnapshot.val().name
              : childSnapshot.val().displayName;
            personalNumber = childSnapshot.val().personalNumber
              ? childSnapshot.val().personalNumber
              : "";
            userData[uid] = { name, personalNumber };
            userData[uid].email = email.toLowerCase();
          }
        });
        if (!uid)
          window.alert(this.props.intl.formatMessage({ id: "error-invite" }));
      });

    return userData;
  };

  inviteSubmit = async event => {
    event.preventDefault();

    const user = await this.getUserFromEmail(`${event.target[0].value}`);
    if (Object.keys(user).length === 0) return;

    this.showInvite();

    await database
      .ref("agreementList/" + this.props.agreement.agreementId + "/users")
      .update(user);

    await this.getPersonalData();
    this.setOtherUser();
  };

  goToSign = () => {
    /* Denna kan optimeras genom att breaka så fort den hittar en paragraph som inte har godkänts av någon eller om det är ett oneManAgreement */

    const listOfToggles = [];
    let isAllToggled = false;
    var oneManAgreement = false;

    this.state.chapters.forEach(chapter => {
      if (chapter && chapter.paragraph) {
        Object.values(chapter.paragraph).forEach(paragraph => {
          if (oneManAgreement) {
            return;
          }
          if (paragraph.uid) {
            if (!oneManAgreement) {
              if (
                Object.keys(paragraph.uid).length ===
                Object.keys(this.props.agreement.users).length
              ) {
                Object.values(paragraph.uid).forEach(toggle => {
                  if (!Object.values(toggle)) {
                    listOfToggles.push(false);
                  }
                });
              } else {
                listOfToggles.push(false);
              }
            }
          } else {
            listOfToggles.push(false);
          }
        });
      }
      /* isAllToggled är sann on listOfToggles inte innehåller något*/

      isAllToggled = listOfToggles.length === 0;
    });
    if (isAllToggled && !oneManAgreement) {
      this.lock();
      this.someoneHasSignedOrLocked();
    }

    if (!isAllToggled && !oneManAgreement) {
      window.alert(this.props.intl.formatMessage({ id: "missing-paragraph" }));
    }
  };

  filterChapters = () => {
    return this.state.chapters.filter(chapter =>
      chapter.title.includes(this.state.search)
    );
  };

  setSearch = search => {
    this.setState(() => ({
      search
    }));
  };

  showSearchBar = () => {
    this.setState({ showSearchBar: this.state.showSearchBar ? false : true });
  };

  getPersonalData = async () => {
    let personalData = {};

    await database
      .ref(`agreementList/${this.props.agreement.agreementId}/users`)
      .on("value", snapshot => {
        personalData = snapshot.val();
        this.setState({ personalData });
      });
  };

  readChaptersFromDatabase = () => {
    database
      .ref("agreementList/" + this.props.agreement.agreementId + "/chapter/")
      .on("value", async snapshot => {
        const chapters = [];

        snapshot.forEach(childSnapshot => {
          chapters.push({
            chapterDatabaseId: childSnapshot.key,
            ...childSnapshot.val()
          });
        });
        await this.setState({ chapters: chapters });
        this.checkIfThereExistParagraph();
      });
  };

  getChapterIdFromDatabase = async () => {
    await database
      .ref("agreementList/" + this.props.agreement.agreementId + "/chapter/")
      .once("value")
      .then(snapshot => {
        const chapters = [];

        snapshot.forEach(childSnapshot => {
          chapters.push({
            chapterDatabaseId: childSnapshot.key,
            ...childSnapshot.val()
          });
        });
        this.setState({
          chapters
        });
      });
  };

  syncSomeoneHasSigned = async () => {
    await database
      .ref("agreementList/" + this.props.agreement.agreementId + "/hasSigned")
      .on("value", snapshot => {
        if (snapshot.val()) this.setState({ someoneHasSigned: true });
        else this.setState({ someoneHasSigned: false });
      });
  };

  someoneHasSignedOrLocked = () => {
    this.setState({
      someoneHasSignedOrLocked:
        this.state.someoneHasSigned || this.state.mode === "locked" // someoneHasSignedOrLocked ska INTE vara en egen state då den kan beräknas utifrån state, någon regel som react har.
    });
  };

  lock = () => {
    database
      .ref("agreementList/" + this.props.agreement.agreementId + "/lock")
      .update({ someoneHasLocked: true }) //set? rensa allt annat
      .then(this.setState({ mode: "locked" }));
  };

  signaturesWillRemove = () => {
    database
      .ref("agreementList/" + this.props.agreement.agreementId + "/lock")
      .on("value", snapshot => {
        if (snapshot.val() && snapshot.val().signaturesWillRemove == true) {
          this.setState({ mode: "open", someoneHasSigned: false });
          this.someoneHasSignedOrLocked();
        }
      });
  };

  unlock = () => {
    const confirmation = {};
    Object.keys(this.props.agreement.users).forEach(user => {
      confirmation[user] = "unlock";
    });

    if (this.state.someoneHasSigned) {
      database
        .ref("agreementList/" + this.props.agreement.agreementId + "/lock")
        .set({
          someoneHasLocked: false,
          requestUnlockAgreementConfirmation: confirmation,
          signaturesWillRemove: true
        })
        .then(async () => {
          await this.signaturesWillRemove();
          database
            .ref(
              "agreementList/" +
                this.props.agreement.agreementId +
                "/hasSigned/"
            )
            .remove();
        })
        .then(
          database
            .ref("agreementList/" + this.props.agreement.agreementId + "/lock")
            .set({ signaturesWillRemove: false })
        );
    } else {
      database
        .ref("agreementList/" + this.props.agreement.agreementId + "/lock")
        .set({
          someoneHasLocked: false,
          requestUnlockAgreementConfirmation: confirmation
        })
        .then(this.setState({ mode: "open" }))
        .then(this.someoneHasSignedOrLocked());
    }
  };

  declineUnlock = () => {
    const confirmation = {};
    Object.keys(this.props.agreement.users).forEach(user => {
      confirmation[user] = "decline";
    });

    database
      .ref(
        "agreementList/" +
          this.props.agreement.agreementId +
          "/lock/userRequestedUnlockAgreement"
      )
      .remove();

    database
      .ref("agreementList/" + this.props.agreement.agreementId + "/lock")
      .update({ requestUnlockAgreementConfirmation: confirmation });
  };

  requestUnlockAgreement = () => {
    database
      .ref("agreementList/" + this.props.agreement.agreementId + "/lock")
      .update({ userRequestedUnlockAgreement: this.props.uid });
  };

  isLocked = () => {
    database
      .ref("agreementList/" + this.props.agreement.agreementId + "/lock")
      .on("value", async snapshot => {
        const someoneHasLocked =
          snapshot.val() && snapshot.val().someoneHasLocked;
        if (someoneHasLocked) {
          await this.setState({ mode: "locked" });
        } else await this.setState({ mode: "open" });
        this.someoneHasSignedOrLocked();
      });
  };

  getRequestUnlockAgreementConfirmation = () => {
    database
      .ref(
        "agreementList/" +
          this.props.agreement.agreementId +
          "/lock/requestUnlockAgreementConfirmation"
      )
      .on("value", snapshot => {
        if (
          snapshot.val() &&
          Object.keys(snapshot.val()).includes(this.props.uid)
        ) {
          const outcome = snapshot.val()[this.props.uid];

          if (outcome === "unlock") {
            this.setState({ requestUnlockAgreementConfirmation: "unlock" });
          } else if (outcome === "decline") {
            this.setState({ requestUnlockAgreementConfirmation: "decline" });
          }
        }
      });
  };

  confirmRequestUnlockAgreementConfirmation = () => {
    database
      .ref(
        "agreementList/" +
          this.props.agreement.agreementId +
          "/lock/requestUnlockAgreementConfirmation/" +
          this.props.uid
      )
      .remove()
      .then(this.setState({ requestUnlockAgreementConfirmation: "" }));
  };

  setOtherUser = () => {
    for (const user in this.state.personalData) {
      if (user !== this.props.uid) {
        this.setState({ otherUser: user });
      }
    }
  };

  changePersonalData = (event, uid) => {
    const personalData = { ...this.state.personalData };
    const name = event.target.name;
    personalData[uid][name] = event.target.value;
    this.setState({ personalData });
  };

  getTodaysDate = () => {
    let today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();

    today = dd + "-" + mm + "-" + yyyy;
    return today;
  };

  checkIfThereExistParagraph = () => {
    let thereExist = false;

    this.state.chapters.forEach(chapter => {
      if (chapter.paragraph && Object.keys(chapter.paragraph).length > 0)
        thereExist = true;
    });
    if (thereExist) this.setThereExistParagraph();
    else {
      this.setState({ thereExistParagraph: false });
    }
  };

  setThereExistParagraph = () => {
    this.setState({ thereExistParagraph: true });
  };

  handleEnter = event => {
    if (event.key === "Enter") {
      this.props.submitAgreementTitle(event);
      this.setState({
        editTitle: false
      });
      event.target.blur();
    }
  };

  async componentDidMount() {
    await this.getPersonalData();
    await this.setOtherUser();
    this.readChaptersFromDatabase();
    this.syncSomeoneHasSigned();
    await this.isLocked();
    this.someoneHasSignedOrLocked();
    await this.getRequestUnlockAgreementConfirmation();
    this.signaturesWillRemove(); // konstigt att denna ska köras här, borde egentligen bara köras när något händer i databasen men funkar inte då :S
  }

  render() {
    const { intl } = this.props;

    return (
      <div className="background screen-holder">
        <div className="top-navbar">
          <BackButton />

          <OutsideClickHandler
            disabled={!this.state.editTitle}
            onOutsideClick={event => {
              this.props.submitAgreementTitle(event);
              this.setState({
                editTitle: false
              });
            }}
          >
            <form className="agreement-title">
              <label className="agreement-title__label">
                <input
                  placeholder={intl.formatMessage({ id: "title" })}
                  value={this.props.agreement.title}
                  onChange={event => this.props.changeAgreementTitle(event)}
                  rows="1"
                  className={
                    "agreement-title__input" +
                    (this.state.editTitle ? "" : " editFalse")
                  }
                  onClick={
                    this.state.someoneHasSignedOrLocked
                      ? null
                      : () => {
                          this.setState({ editTitle: true });
                        }
                  }
                  readOnly={this.state.someoneHasSignedOrLocked}
                  onKeyDown={event => {
                    this.handleEnter(event);
                  }}
                />
              </label>
            </form>
          </OutsideClickHandler>

          <button className="magnifier" onClick={this.showSearchBar}>
            <i className="fas fa-search" />
          </button>
          {!this.props.allHasSigned(this.props.agreement) && (
            <Dropdown
              showInvite={this.showInvite}
              deleteAgreement={this.props.deleteAgreement}
              users={this.props.agreement.users}
            />
          )}
        </div>

        {this.state.showSearchBar && <Search setSearch={this.setSearch} />}

        <div className="agreement-content">
          {this.state.showInvite &&
            Object.keys(this.props.agreement.users).length < 2 && (
              <div className="agreement-box agreement-box--invite">
                <form onSubmit={event => this.inviteSubmit(event)}>
                  <label>
                    <input
                      style={{ width: "95%" }}
                      placeholder={intl.formatMessage({ id: "enter-email" })}
                      autoFocus
                    />
                  </label>
                  <input
                    className="button button--add-new button--send-invite"
                    type="submit"
                    value={intl.formatMessage({ id: "send-invite" })}
                  />
                </form>
              </div>
            )}
          <Parties
            personalData={this.state.personalData}
            agreementId={this.props.agreement.agreementId}
            changePersonalData={this.changePersonalData}
            showInvite={this.showInvite}
            someoneHasSignedOrLocked={this.state.someoneHasSignedOrLocked}
          />
          {this.state.chapters.map((p, i) => (
            <Chapter
              key={this.state.chapters[i].chapterDatabaseId}
              chapterIndex={i}
              agreementId={this.props.agreement.agreementId}
              uid={this.props.uid}
              removeChapter={this.removeChapter}
              changeTitle={this.changeTitle}
              chapters={this.state.chapters}
              submitTitle={this.submitTitle}
              editChapter={this.editChapter}
              users={this.props.agreement.users}
              chapterDatabaseId={this.state.chapters[i].chapterDatabaseId}
              displayName={this.props.displayName}
              someoneHasSignedOrLocked={this.state.someoneHasSignedOrLocked}
              search={this.state.search}
              otherUser={this.state.otherUser}
              handleEnterChapterTitle={this.handleEnterChapterTitle}
            />
          ))}
          {!this.state.someoneHasSignedOrLocked && (
            <button
              className="button button--add-new"
              onClick={() => this.onAddChapter({ title: "", edit: false })}
            >
              <FormattedMessage
                id="new-chapter"
                defaultMessage="Nytt Kapitel"
              />
            </button>
          )}
          {this.state.requestUnlockAgreementConfirmation === "unlock" && (
            <div style={{ backgroundColor: "green" }}>
              The agreement has been unlocked
              <button
                className="button"
                onClick={this.confirmRequestUnlockAgreementConfirmation}
              >
                Ok
              </button>
            </div>
          )}
          {this.state.requestUnlockAgreementConfirmation === "decline" && (
            <div style={{ backgroundColor: "red" }}>
              The request to unlock the agreement has been declined
              <button
                className="button"
                onClick={this.confirmRequestUnlockAgreementConfirmation}
              >
                Ok
              </button>
            </div>
          )}
          {Object.keys(this.props.agreement.users).length > 1 &&
            this.state.chapters.length > 0 &&
            this.state.thereExistParagraph &&
            !this.state.someoneHasSignedOrLocked && (
              <button
                className="button button--large"
                onClick={() => this.goToSign()}
              >
                {" "}
                <FormattedMessage id="lock-agreement" />
              </button>
            )}
          {this.state.someoneHasSignedOrLocked && (
            <Sign
              agreement={this.props.agreement}
              uid={this.props.uid}
              agreementId={this.props.agreement.agreementId}
              syncSomeoneHasSigned={this.syncSomeoneHasSigned}
              personalData={this.state.personalData}
              unlock={this.unlock}
              mode={this.state.mode}
              allHasSigned={this.props.allHasSigned}
              requestUnlockAgreement={this.requestUnlockAgreement}
              declineUnlock={this.declineUnlock}
              otherUser={this.state.otherUser}
              getTodaysDate={this.getTodaysDate}
            />
          )}
        </div>
      </div>
    );
  }
}

export default injectIntl(Agreement);
