export default {
  sv: {
    "by-signing-in": "Genom att logga in godkänner du våra ",
    terms: "användarvillkor",
    "privacy-policy": "integritetspolicy",
    google: "Logga in med Google",
    "new-chapter": "Nytt kapitel ",
    "lock-agreement": "Lås avtalet och gå till signering",
    "new-paragraph": "Ny Paragraf",
    "my-account": "Mitt konto",
    "info-updated": "Din information har uppdaterats",
    draft: "Utkast",
    agreed: "Överens",
    "create-agreement": "Skapa eget avtal",
    title: "Titel",
    search: "Sök i paragrafer i detta avtal",
    "invite-someone": "Bjud in någon",
    "send-invite": "Skicka inbjudan",
    "enter-email": "Ange e-postadress till personen du vill bjuda in",
    templates: "Mallar",
    members: "Medlemmar",
    "delete-agreement": "Radera avtal",
    "save-and-close": "Spara & Stäng",
    save: "Spara",
    delete: "Radera",
    parties: "Det här avtalet gäller mellan ",
    and: " och ",
    "your-name": "För- och efternamn",
    name: "Namn",
    "personal-number": "Personnummer",
    "my-account-paragraph":
      "Ange information om dig själv som kommer att synas på dina avtal",
    "log-out": "Logga ut",
    "delete-account": "Radera konto",
    pdf: "Ladda ner PDF",
    loading: "Laddar pdf...",
    sign: "Signera",
    clear: "Sudda",
    "copy-agreement": "Använd mall",
    crow: "Vänligen sätt en kråka",
    "error-invite":
      "Användaren du försöker bjuda in finns inte registrerad. Kontrollera stavningen eller dela en länk till personen och försök igen.",
    "missing-paragraph": "Alla paragrafer är inte godkända ännu.",
    "cant-touch-this": "can't touch this",
    "created-with": "Det här avtalet skapades med Splink",
    "thanks-for-feedback": "Tack för din åsikt.",
    "opinion-please":
      "Det finns alltid utrymme för förbättring. Kom gärna med förslag!",
    send: "Skicka"
  },
  en: {
    "new-chapter": "Add new Chapter ",
    "enter-email": "Enter someones email to have them join this agreement.",
    loading: "Loading document...",
    "created-with": "This agreement was created with Splink"
  }
};
