import React from "react";
import { database } from "./Firebase";
import { injectIntl } from "react-intl";

class Toggle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toggle: false,
      toggleImmediately: false //Så att den får en klass så att den ser togglad ut direkt och inte togglas när man kommer in
    };
  }

  toggle = event => {
    if (this.props.uid === this.props.userWhoCanToggleThis) {
      this.props.saveParagraphContent(this.props.paragraphIndex);
      database
        .ref(
          "agreementList/" +
            this.props.agreementId +
            "/chapter/" +
            this.props.chapterDatabaseId +
            "/paragraph/" +
            this.props.paragraphDatabaseId +
            "/uid/" +
            this.props.uid
        )
        .update({ toggle: event.target.checked })
        .then(this.setState({ toggle: event.target.checked }));
    } else {
      const { intl } = this.props;
      window.alert(intl.formatMessage({ id: "cant-touch-this" }));
    }
  };

  readToggleFromDatabase = () => {
    database
      .ref(
        "agreementList/" +
          this.props.agreementId +
          "/chapter/" +
          this.props.chapterDatabaseId +
          "/paragraph/" +
          this.props.paragraphDatabaseId +
          "/uid/"
      )
      .on("value", snapshot => {
        if (snapshot.val() == null) {
          this.setState({ toggleImmediately: false, toggle: false });
        } else {
          snapshot.forEach(childSnapshot => {
            if (childSnapshot.key === this.props.userWhoCanToggleThis) {
              this.setState(childSnapshot.val());
              if (childSnapshot.val().toggle) {
                this.setState({ toggleImmediately: true });
              }
            }
          });
        }
      });
  };

  componentDidMount() {
    this.readToggleFromDatabase();
  }

  render() {
    return (
      <div className="toggleDiv">
        <label className="switch">
          <input
            onChange={this.toggle}
            checked={this.state.toggle}
            type={"checkbox"}
          />
          <span
            className={`slider ${
              this.state.toggleImmediately ? "slided " : " "
            }round`}
          />
        </label>
        {this.props.uid === this.props.userWhoCanToggleThis &&
          this.props.togglesStatus == "allIsToggled" && (
            <i className="fab fa-angellist" />
          )}
        <p className="part-data">
          {this.props.users[this.props.userWhoCanToggleThis].name}
        </p>
      </div>
    );
  }
}

export default injectIntl(Toggle);
