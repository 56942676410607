import React, { Component } from "react";
import Home from "./Home";
import { firebase } from "./Firebase";
import "../styles/App.scss";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import Login from "./Login";
import { IntlProvider } from "react-intl";
import Messages from "../Messages";
import ScrollToTop from "./ScrollToTop";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {}
    };
  }

  logout = this.logout.bind(this);
  logout() {
    firebase.auth().signOut();
  }

  onLogin = this.onLogin.bind(this);
  onLogin() {
    var provider = new firebase.auth.GoogleAuthProvider();

    firebase
      .auth()
      .signInWithPopup(provider)
      .then(function(result) {
        // This gives you a Google Access Token. You can use it to access the Google API.
        //var token = result.credential.accessToken;
        // The signed-in user info.
        //var user = result.user;
        // ...
      })
      .catch(function(error) {
        // Handle Errors here.
        // var errorCode = error.code;
        // var errorMessage = error.message;
        // // The email of the user's account used.
        // var email = error.email;
        // // The firebase.auth.AuthCredential type that was used.
        // var credential = error.credential;
        // ...
      });
  }

  authListener() {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.setState({ user });
      } else {
        this.setState({ user: {} });
      }
    });
  }

  componentDidMount() {
    this.authListener();
  }

  render() {
    let homeProps = {};
    if (this.state.user && this.state.user.uid) {
      homeProps = {
        uid: this.state.user.uid,
        email: this.state.user.email,
        logout: this.logout,
        displayName: this.state.user.displayName,
        user: this.state.user
      };
    }

    return (
      <IntlProvider locale="sv" messages={Messages["sv"]}>
        <Router>
          <div className="login-screen">
            <ScrollToTop />
            <Route
              path="/"
              render={props =>
                this.state.user && this.state.user.uid ? (
                  <>
                    <Home {...homeProps} />
                    <Redirect to="/home" />
                  </>
                ) : (
                  <>
                    <Redirect to="/login" />
                    <Login onLogin={this.onLogin} />
                  </>
                )
              }
            />
          </div>
        </Router>
      </IntlProvider>
    );
  }
}

export default App;
