import React, { Component } from "react";
import { database } from "./Firebase";
import "../styles/Parties.scss";
import { FormattedMessage, injectIntl } from "react-intl";
import OutsideClickHandler from "react-outside-click-handler";

class Parties extends Component {
  constructor(props) {
    super(props);
    this.state = { editing: false };
  }

  submitPersonalData = event => {
    event.preventDefault();

    this.setState({ editing: false });

    database
      .ref(`agreementList/${this.props.agreementId}/users`)
      .set(this.props.personalData);
  };

  handleOutsideClick = () => {
    this.setState({ editing: false });

    database
      .ref(`agreementList/${this.props.agreementId}/users`)
      .set(this.props.personalData);
  };

  render() {
    const { intl } = this.props;
    let name1 = "";
    let pn1 = "";
    let comma1 = "";
    let name2 = "";
    let pn2 = "";
    let comma2 = "";
    let noOtherPerson = false;

    if (this.props.personalData[Object.keys(this.props.personalData)[0]])
      name1 = this.props.personalData[Object.keys(this.props.personalData)[0]]
        .name;

    if (
      this.props.personalData[Object.keys(this.props.personalData)[0]] &&
      this.props.personalData[Object.keys(this.props.personalData)[0]]
        .personalNumber
    ) {
      pn1 = this.props.personalData[Object.keys(this.props.personalData)[0]]
        .personalNumber;
      comma1 = ", ";
    }

    if (this.props.personalData[Object.keys(this.props.personalData)[1]])
      name2 = this.props.personalData[Object.keys(this.props.personalData)[1]]
        .name;
    else noOtherPerson = true;

    if (
      this.props.personalData[Object.keys(this.props.personalData)[1]] &&
      this.props.personalData[Object.keys(this.props.personalData)[1]]
        .personalNumber
    ) {
      pn2 = this.props.personalData[Object.keys(this.props.personalData)[1]]
        .personalNumber;
      comma2 = ", ";
    }

    let parterText;
    if (!noOtherPerson) {
      parterText = (
        <div>
          <FormattedMessage id="parties" />
          <form
            onSubmit={event => {
              this.submitPersonalData(event);
            }}
          >
            {!this.state.editing && (
              <strong
                onClick={
                  !this.props.someoneHasSignedOrLocked
                    ? () => {
                        this.setState({ editing: true });
                      }
                    : undefined
                }
              >
                {name1}
              </strong>
            )}
            {this.state.editing && (
              <label>
                <input
                  className="parties__input"
                  name="name"
                  type="text"
                  placeholder={intl.formatMessage({ id: "name" })}
                  value={name1}
                  onChange={event => {
                    this.props.changePersonalData(
                      event,
                      Object.keys(this.props.personalData)[0]
                    );
                  }}
                  autoFocus
                />
              </label>
            )}
            {comma1}
            {!this.state.editing && (
              <strong
                onClick={
                  !this.props.someoneHasSignedOrLocked
                    ? () => {
                        this.setState({ editing: true });
                      }
                    : undefined
                }
              >
                {pn1}
              </strong>
            )}
            {this.state.editing && (
              <label>
                <input
                  className="parties__input"
                  name="personalNumber"
                  type="tel"
                  placeholder={intl.formatMessage({ id: "personal-number" })}
                  value={pn1}
                  onChange={event => {
                    this.props.changePersonalData(
                      event,
                      Object.keys(this.props.personalData)[0]
                    );
                  }}
                />
              </label>
            )}
            <FormattedMessage id="and" />
            {!this.state.editing && (
              <strong
                onClick={
                  !this.props.someoneHasSignedOrLocked
                    ? () => {
                        this.setState({ editing: true });
                      }
                    : undefined
                }
              >
                {name2}
              </strong>
            )}
            {this.state.editing && (
              <label>
                <input
                  className="parties__input"
                  name="name"
                  type="text"
                  placeholder={intl.formatMessage({ id: "name" })}
                  value={name2}
                  onChange={event => {
                    this.props.changePersonalData(
                      event,
                      Object.keys(this.props.personalData)[1]
                    );
                  }}
                />
              </label>
            )}
            {comma2}
            {!this.state.editing && (
              <strong
                onClick={
                  !this.props.someoneHasSignedOrLocked
                    ? () => {
                        this.setState({ editing: true });
                      }
                    : undefined
                }
              >{`${pn2}. `}</strong>
            )}
            {this.state.editing && (
              <label>
                <input
                  className="parties__input"
                  name="personalNumber"
                  type="tel"
                  placeholder={intl.formatMessage({ id: "personal-number" })}
                  value={pn2}
                  onChange={event => {
                    this.props.changePersonalData(
                      event,
                      Object.keys(this.props.personalData)[1]
                    );
                  }}
                />
              </label>
            )}
            <input type="submit" value="Submit" style={{ display: "none" }} />
          </form>
        </div>
      );
    } else if (noOtherPerson) {
      parterText = (
        <div className="parties-content">
          <div>
            <FormattedMessage id="parties" />
            <form
              onSubmit={event => {
                this.submitPersonalData(event);
              }}
            >
              {!this.state.editing && (
                <strong
                  onClick={() => {
                    this.setState({ editing: true });
                  }}
                >
                  {name1}
                </strong>
              )}
              {this.state.editing && (
                <label>
                  <input
                    className="parties__input"
                    name="name"
                    type="text"
                    placeholder={intl.formatMessage({ id: "name" })}
                    value={name1}
                    onChange={event => {
                      this.props.changePersonalData(
                        event,
                        Object.keys(this.props.personalData)[0]
                      );
                    }}
                    autoFocus
                  />
                </label>
              )}
              {comma1}
              {!this.state.editing && (
                <strong
                  onClick={() => {
                    this.setState({ editing: true });
                  }}
                >
                  {pn1}
                </strong>
              )}
              {this.state.editing && (
                <label>
                  <input
                    className="parties__input"
                    name="personalNumber"
                    type="tel"
                    placeholder={intl.formatMessage({ id: "personal-number" })}
                    value={pn1}
                    onChange={event => {
                      this.props.changePersonalData(
                        event,
                        Object.keys(this.props.personalData)[0]
                      );
                    }}
                  />
                </label>
              )}
              <input type="submit" value="Submit" style={{ display: "none" }} />
            </form>
            <FormattedMessage id="and" />
            <button className="button" onClick={this.props.showInvite}>
              <FormattedMessage id="invite-someone" />
            </button>
            .
          </div>
        </div>
      );
    }

    return (
      <div className="agreement-box agreement-box--parties">
        <div className="chapter-header chapter-header--parties">
          {
            <OutsideClickHandler
              onOutsideClick={() => this.handleOutsideClick()}
              disabled={!this.state.editing}
            >
              {parterText}
            </OutsideClickHandler>
          }
        </div>
      </div>
    );
  }
}

export default injectIntl(Parties);
