import React, { Component } from "react";
import BackButton from "./BackButton";
import "../styles/Conditions.scss";

class Conditions extends Component {
  render() {
    const conditions = `
    Senast uppdaterad: 2019-12-31

    Välkommen till Splink. Denna app ("Appen") innehåller föreslagna mallar som erbjuds med avsikt att vara ett hjälpmedel för dig som använder Appen (“Användare”) vid upprättande av olika former av avtal. I Appen finns förslag på standardiserade mallavtal med färdigtryckt innehåll och tomma mallar som Användaren kan använda för att skapa ett eget innehåll. Användaren ansvarar själv för de avtal som ingås med hjälp av dessa mallar oavsett om standardiserade mallar används eller om Användaren skapar avtal med eget innehåll. 
    
    Dessa användarvillkor ("Villkoren") gäller för alla som loggar in i Appen.  
    
    1. ALLMÄNT 
    Dessa användarvillkor (”Villkoren”) gäller då du använder Appen. Läs noga igenom Villkoren innan du loggar in som Användare. Genom att logga in godkänner och accepterar du Villkoren. Om du inte godkänner Villkoren ska du inte logga in och därmed heller inte använda Appen. 
    
    2. TILLÅTEN ANVÄNDNING 
    Genom att logga in så får du använda de funktioner som Appen erbjuder i enlighet med Villkoren och vad som följer av gällande lagstiftning. För eget personligt bruk är det tillåtet att ladda ner, kopiera och spara information som framgår av Appen. Det är inte tillåtet att sprida, modifiera, distribuera eller publicera innehåll som tillhandahålls av Appen eller att använda Appens kod eller delar av den i kommersiellt syfte. 
    
    3. UNDERHÅLL OCH BEGRÄNSNINGAR 
    
    Splink förbehåller sig rätten att när som helst utan ansvar eller ersättningsskyldighet uppdatera, uppgradera, ändra, tillfälligt eller permanent begränsa eller stänga ner Appen eller åtkomsten till Appen för dig som Användare utan föregående varsel. 
    
    Splink har för avsikt att all information i Appen ska vara korrekt och så uppdaterad som möjligt. Splink kan dock inte garantera tillgängligheten till Appen eller tillförlitligheten eller korrektheten på information som finns presenterad i Appen. Splink kan inte ställas till svars om Appen ligger nere eller någon information eller funktionalitet i Appen inte fungerar som förväntat. 
    
    Splink åtar sig inga skyldigheter att tillhandahålla någon form av support eller underhåll av Appen.  
    
    Användare uppmärksammas på att driftsavbrott m.m. kan förekomma som påverkar tillgängligheten av Appen. Splink ansvarar ej för eventuella störningar som påverkar tillgängligheten.  
    
    4. AVTALSBROTT OCH ANSVAR 
    Appen tillhandahålls i befintligt skick och utan några som helst garantier eller utfästelser av något slag. Användare ansvarar för att Appen används i enlighet med dessa Villkor, andra villkor som anges i Appen samt vid var tid gällande lagstiftning. Användaren ansvarar vidare för att uppgifter som Användaren själv lägger upp i Appen inte strider mot gällande lagstiftning. Splink tar inget ansvar för den information som Användare lägger upp i Appen.  
    
    Splink har rätt att när som helst stänga av en Användare och avsluta ett konto om Användaren bryter mot Villkoren eller annars använder Appen på ett sätt som kan orsaka Splink eller tredje man skada. 
    
    Splink garanterar inte och tar inte ansvar för riktigheten eller fullständigheten av den information, text, mallar, grafik eller andra objekt som finns i Appen eller för att Appen fyller en viss funktion. Vidare tar Splink inget ansvar för de avtal som Användaren ingår genom användande av Appen. Användandet av Appen sker helt och hållet på Användarens eget ansvar. Splink åtar sig inget ansvar för eventuell skada som Användare åsamkas vid användande av Appen.  
    
    Splink friskriver sig härmed från alla garantiåtaganden vad gäller Appen eller programvara eller andra tjänster som tillhandahålls via Appen vare sig de är uttryckliga, underförstådda eller lagstadgade, till exempel vad gäller tillfredsställande kvalitet, lämplighet för särskilt ändamål, korrekthet av information, icke intrång i tredje parts rättigheter. Splink ger inga garantier mot störningar vid användning av Appen, för att Appen motsvarar dina krav eller förväntningar, för att användandet av Appen kan ske utan avbrott eller problem eller för att eventuella fel i Appen kommer att åtgärdas.  
    
    5. IMMATERIELLA RÄTTIGHETER 
    Innehållet i Appen liksom bakomliggande programvaror är skyddade enligt lagen om upphovsrätt. Äganderätt, upphovsrätt och alla övriga rättigheter till Appen och det material som Appen tillhandahåller tillhör Splink. Användaren accepterar att endast erhålla en tillfällig nyttjanderätt att använda Appen i enlighet med de funktioner som Appen möjliggör.   
    
    Användare får inte modifiera hela eller någon del av Appen eller kombinera eller inkorporera hela eller någon del av Appen i något annat program eller system. 
    
    6. ÄNDRING AV VILLKOR 
    Splink har rätt att när som helst ändra Appen, ändra Villkoren eller sluta att tillhandahålla Appen. Vid en väsentlig förändring av Villkoren kommer Splink att publicera de nya Villkoren i Appen. Det är användarens ansvar att hålla sig uppdaterad om vilka Villkor som gäller.  
    
    Om du inte godtar de ändrade Villkoren kan du avsluta ditt konto på sätt som framgår av Appen.  
    
    7. BEHANDLING AV PERSONUPPGIFTER 
    
    Splink samlar in och behandlar personuppgifter om dig när du loggar in och använder Appen. De personuppgifter Splink behandlar är sådana uppgifter som du själv registrerar t.ex. namn, födelsedatum, adress, e-postadress och telefonnummer samt information som genereras vid din användning av Appen. Genom att logga in samtycker du till att dina personuppgifter behandlas. Dina personuppgifter bevaras endast så länge som det finns behov av att bevara dem för att fullgöra de ändamål som uppgifterna samlades in för. Uppgifterna kan komma att sparas längre om det behövs för att följa lagkrav eller för att bevaka Splinks rättsliga intressen.  
    
    När du avslutar ditt konto raderas all din data med undantag för sådan information som det enligt lag finns en skyldighet att spara samt information i de avtal som du själv ingått med annan part så länge den parten också har ett konto i Appen. 
    
    8. LAGVAL 
    
    Svensk rätt tillämpas på dessa Villkor.`;

    return (
      <div className="background screen-holder conditions">
        <div className="top-navbar">
          <BackButton />
          <h2 className="top-navbar__title capitalize">{this.props.heading}</h2>
        </div>
        {conditions.split("\n").map((i, key) => {
          return (
            <p className="line-break" key={key}>
              {i}
            </p>
          );
        })}
      </div>
    );
  }
}

export default Conditions;
