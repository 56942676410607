import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import OutsideClickHandler from "react-outside-click-handler";

class Dropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listOpen: false
    };
  }

  toggleList() {
    this.setState(prevState => ({
      listOpen: !prevState.listOpen
    }));
  }

  deleteAgreement = () => {
    this.props.deleteAgreement();
    this.props.history.push("/home");
  };

  render() {
    return (
      <OutsideClickHandler
        disabled={!this.state.listOpen}
        onOutsideClick={() => this.toggleList()}
      >
        <div className="dd-wrapper">
          <div className="dd-header">
            <div className="dd-header-title" onClick={() => this.toggleList()}>
              <svg
                className="three-dots"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path d="M296 136c0-22.002-17.998-40-40-40s-40 17.998-40 40 17.998 40 40 40 40-17.998 40-40zm0 240c0-22.002-17.998-40-40-40s-40 17.998-40 40 17.998 40 40 40 40-17.998 40-40zm0-120c0-22.002-17.998-40-40-40s-40 17.998-40 40 17.998 40 40 40 40-17.998 40-40z" />
              </svg>
            </div>
          </div>

          {this.state.listOpen && (
            <ul className="dd-list">
              <li className="dd-list-item">
                <div className="dd-list-item__header">
                  <FormattedMessage id="members" />
                </div>
                <div className="dd-list-item__body">
                  {Object.values(this.props.users).map(user => {
                    return (
                      <div className="member" key={user.personalNumber}>
                        <div className="name">{user.name}</div>
                        <div className="email">{user.email}</div>
                      </div>
                    );
                  })}
                </div>
              </li>

              <li className="dd-list-item" onClick={this.deleteAgreement}>
                <div className="dd-list-item__header delete-agreement">
                  <FormattedMessage id="delete-agreement" />
                </div>
              </li>
            </ul>
          )}
        </div>
      </OutsideClickHandler>
    );
  }
}

export default withRouter(Dropdown);
