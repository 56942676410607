import React, { Component } from "react";
import TemplateParagraph from "./TemplateParagraph";
import { database } from "./Firebase";
import Textarea from "react-textarea-autosize";
import { injectIntl } from "react-intl";

class TemplateChapter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paragraphList: []
    };
  }

  readParagraphsFromDatabase = () => {
    database
      .ref(
        "templateList/" +
          this.props.agreementId +
          "/chapter/" +
          this.props.chapterDatabaseId +
          "/paragraph/"
      )
      .once("value")
      .then(snapshot => {
        const paragraphList = [];
        snapshot.forEach(childSnapshot => {
          paragraphList.push({
            paragraphDatabaseId: childSnapshot.key,
            ...childSnapshot.val()
          });
        });
        this.setState({ paragraphList: paragraphList });
      });
  };

  componentDidMount() {
    this.readParagraphsFromDatabase();
  }

  filterParagraphs = () => {
    return this.state.paragraphList.filter(paragraph =>
      paragraph.value.includes(this.props.search)
    );
  };

  render() {
    const { intl } = this.props;

    return (
      <div className="agreement-box agreement-box--chapter">
        <div className="chapter-header chapter-header--chapter">
          <span>{this.props.chapterIndex + 1}.</span>
          <form
            className="agreement-title"
            onSubmit={event =>
              this.props.submitTitle(this.props.chapterIndex, event)
            }
          >
            <label className="agreement-title__label">
              <Textarea
                placeholder={intl.formatMessage({ id: "title" })}
                value={this.props.chapters[this.props.chapterIndex].title}
                onChange={event =>
                  this.props.changeTitle(this.props.chapterIndex, event)
                }
                rows="1"
                className={
                  "chapter-title__textarea" +
                  (this.props.chapters[this.props.chapterIndex].edit
                    ? ""
                    : " editFalse")
                }
                readOnly
              />
            </label>
            {this.props.chapters[this.props.chapterIndex].edit && (
              <input className="button" type="submit" value="Save" />
            )}
          </form>
        </div>
        {this.filterParagraphs().map((p, i) => (
          <TemplateParagraph
            value={this.filterParagraphs()[i].value}
            edit={this.filterParagraphs()[i].edit}
            key={this.filterParagraphs()[i].paragraphDatabaseId}
            paragraphIndex={i}
            chapterIndex={this.props.chapterIndex}
            onRemoveParagraph={this.onRemoveParagraph}
            clickOnParagraph={this.clickOnParagraph}
            handleChange={this.handleChange}
            handleSubmit={this.handleSubmit}
            users={this.props.users}
            uid={this.props.uid}
            agreementId={this.props.agreementId}
            chapterDatabaseId={this.props.chapterDatabaseId}
            paragraphDatabaseId={this.filterParagraphs()[i].paragraphDatabaseId}
            readParagraphsFromDatabase={this.readParagraphsFromDatabase}
            displayName={this.props.displayName}
          />
        ))}
      </div>
    );
  }
}

export default injectIntl(TemplateChapter);
