import React, { Component } from "react";
import Textarea from "react-textarea-autosize";

class TemplateParagraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paragraphHidden: false
    };
  }

  hideParagraph = () => {
    this.setState({ paragraphHidden: !this.state.paragraphHidden });
  };

  render() {
    return (
      <div className={this.state.paragraphHidden ? "hidden" : ""}>
        <Textarea
          className={"paragraph " + (this.props.edit ? "hidden" : "")}
          value={`§${this.props.chapterIndex + 1}.${this.props.paragraphIndex +
            1} ${this.props.value}`}
          readOnly
        />
      </div>
    );
  }
}

export default TemplateParagraph;
