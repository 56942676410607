import React from "react";
import {
  PDFDownloadLink,
  Document,
  Page,
  View,
  Text,
  StyleSheet,
  Image
} from "@react-pdf/renderer";
import SignatureData from "./SignatureData";
import { injectIntl } from "react-intl";

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
    fontSize: 11,
    padding: 30
  },
  section: {
    margin: 10,
    padding: 30,
    flexGrow: 1
  },
  image: {
    height: 100,
    borderColor: "lightgrey",
    borderStyle: "solid",
    borderWidth: 1
  },
  chapterTitle: {
    fontSize: 13,
    paddingBottom: 4
  },
  signature: {
    width: "45%"
  },
  signaturesContainer: {
    flex: 1,
    justifyContent: "space-between",
    flexDirection: "row"
  },
  h1: {
    fontSize: 15,
    marginBottom: 11
  },
  paragraph: {
    lineHeight: 1.3,
    marginTop: 4,
    marginBottom: 9
  }
});

class PDF extends React.Component {
  partiesText = intl => {
    let name1 = "";
    let pn1 = "";
    let comma1 = "";
    let name2 = "";
    let pn2 = "";
    let comma2 = "";

    if (this.props.personalData[Object.keys(this.props.personalData)[0]])
      name1 = this.props.personalData[Object.keys(this.props.personalData)[0]]
        .name;

    if (
      this.props.personalData[Object.keys(this.props.personalData)[0]] &&
      this.props.personalData[Object.keys(this.props.personalData)[0]]
        .personalNumber
    ) {
      pn1 = this.props.personalData[Object.keys(this.props.personalData)[0]]
        .personalNumber;
      comma1 = ", ";
    }

    if (this.props.personalData[Object.keys(this.props.personalData)[1]])
      name2 = this.props.personalData[Object.keys(this.props.personalData)[1]]
        .name;

    if (
      this.props.personalData[Object.keys(this.props.personalData)[1]] &&
      this.props.personalData[Object.keys(this.props.personalData)[1]]
        .personalNumber
    ) {
      pn2 = this.props.personalData[Object.keys(this.props.personalData)[1]]
        .personalNumber;
      comma2 = ", ";
    }

    return (
      <>
        {intl.formatMessage({ id: "parties" })}
        {name1}
        {comma1}
        {pn1} {intl.formatMessage({ id: "and" })}
        {name2}
        {comma2}
        {pn2}.
      </>
    );
  };

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return false;
  }

  render() {
    const { intl } = this.props;

    return (
      <div className="pdf-download-link">
        <PDFDownloadLink
          document={
            <Document>
              <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                  <Text style={styles.h1}>{this.props.agreement.title}</Text>
                  <View>
                    <Text>{this.partiesText(intl)}</Text>
                  </View>
                  {Object.values(this.props.agreement.chapter).map(
                    (chapter, i) => {
                      return (
                        <View key={i}>
                          <Text style={styles.chapterTitle}>
                            {`\n${i + 1}. ${chapter.title}`}
                          </Text>
                          {chapter.paragraph &&
                            Object.values(chapter.paragraph).map(
                              (paragraph, j) => {
                                return (
                                  <Text
                                    style={styles.paragraph}
                                    key={j}
                                  >{`§${i + 1}.${j + 1}  ${
                                    paragraph.value
                                  }`}</Text>
                                );
                              }
                            )}
                        </View>
                      );
                    }
                  )}
                  <View style={styles.signaturesContainer}>
                    <View style={styles.signature}>
                      <Image
                        src={this.props.signaturesDataURL[0]}
                        style={styles.image}
                      />
                      <Text>
                        <SignatureData
                          getTodaysDate={this.props.getTodaysDate}
                          personalData={this.props.personalData}
                          part={this.props.uid}
                          signatures={this.props.signatures}
                        />
                      </Text>
                    </View>
                    <View style={styles.signature}>
                      <Image
                        src={this.props.signaturesDataURL[1]}
                        style={styles.image}
                      />
                      <Text>
                        <SignatureData
                          getTodaysDate={this.props.getTodaysDate}
                          personalData={this.props.personalData}
                          part={this.props.otherUser}
                          signatures={this.props.signatures}
                        />
                      </Text>
                    </View>
                  </View>
                  <Text>{intl.formatMessage({ id: "created-with" })}</Text>
                </View>
              </Page>
            </Document>
          }
          fileName={`${this.props.agreement.title}.pdf`}
        >
          {({ blob, url, loading, error }) =>
            loading
              ? intl.formatMessage({ id: "loading" })
              : intl.formatMessage({ id: "pdf" })
          }
        </PDFDownloadLink>
      </div>
    );
  }
}

export default injectIntl(PDF);
