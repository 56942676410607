import React, { Component } from "react";
import { database } from "./Firebase";
import "../styles/Feedback.scss";
import { FormattedMessage, injectIntl } from "react-intl";

class Feedback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      feedbackValue: ""
    };
  }

  handleChange = e => {
    this.setState({
      feedbackValue: e.target.value
    });
  };

  handleSubmit = async e => {
    e.preventDefault();

    await database.ref("feedback/" + this.props.user.uid).push({
      feedback: this.state.feedbackValue,
      displayName: this.props.user.displayName,
      email: this.props.user.email,
      time: this.props.getDateTime()
    });

    this.setState({
      feedbackValue: ""
    });

    window.alert(this.props.intl.formatMessage({ id: "thanks-for-feedback" }));
    this.props.toggleFeedback();
  };

  render() {
    const { intl } = this.props;

    return (
      <div
        className={
          "feedback" + (this.props.showFeedback ? " feedback--show" : "")
        }
      >
        <p className="feedback--text">
          <FormattedMessage id="opinion-please" />
        </p>
        <form onSubmit={this.handleSubmit}>
          <textarea
            className="feedback--input"
            onChange={this.handleChange}
            value={this.state.feedbackValue}
            autoFocus
            rows={2}
          ></textarea>
          <input
            className="button"
            type="submit"
            value={intl.formatMessage({ id: "send" })}
          />
        </form>
      </div>
    );
  }
}

export default injectIntl(Feedback);
