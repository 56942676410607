import React from "react";
import CanvasDraw from "react-canvas-draw";
import { database } from "./Firebase";
import SignatureData from "./SignatureData";
import PDF from "./PDF";
import { FormattedMessage, injectIntl } from "react-intl";

class Sign extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      signatures: null,
      requestUnlock: ""
    };
  }

  saveSignature = intl => {
    const nonEmptySignature = '"x"';
    if (!this.saveableCanvas.getSaveData().includes(nonEmptySignature)) {
      const crowMessage = intl.formatMessage({ id: "crow" });
      window.alert(crowMessage);
    } else {
      const signatures = { ...this.state.signatures };
      signatures[this.props.uid] = {
        signature: this.saveableCanvas.getSaveData(),
        date: this.props.getTodaysDate()
      };

      database
        .ref(
          "agreementList/" +
            this.props.agreementId +
            "/hasSigned/" +
            this.props.uid
        )
        .set({
          signature: this.saveableCanvas.getSaveData(),
          date: this.props.getTodaysDate()
        })
        .then(this.setState({ signatures }))
        .then(this.props.syncSomeoneHasSigned());
    }
  };

  getSignaturesFromDatabase = async () => {
    let signatures = [];

    await database
      .ref("agreementList/" + this.props.agreementId + "/hasSigned/")
      .on("value", snapshot => {
        signatures = snapshot.val();
        this.setState({ signatures }, () => {
          this.getSignaturesDataURL();
        });
      });
  };

  possibleToSign = () => {
    return (
      !this.state.signatures ||
      (this.state.signatures && !this.state.signatures[this.props.uid])
    );
  };

  getRequestUnlockStatus = () => {
    database
      .ref(
        "agreementList/" +
          this.props.agreementId +
          "/lock/userRequestedUnlockAgreement"
      )
      .on("value", snapshot => {
        let userRequestedUnlockAgreement = snapshot.val();
        if (userRequestedUnlockAgreement == this.props.uid) {
          this.setState({ requestUnlock: "hasSentRequest" });
        } else if (
          this.props.otherUser &&
          userRequestedUnlockAgreement == this.props.otherUser
        ) {
          this.setState({ requestUnlock: "haveReceivedRequest" });
        } else if (userRequestedUnlockAgreement == null) {
          this.setState({ requestUnlock: "" });
        }
      });
  };

  getSignaturesDataURL = () => {
    const allHasSigned =
      this.state.signatures && Object.keys(this.state.signatures).length >= 2; //funkar tyvärr inte med this.props.allHasSigned(this.props.agreement), har inte hunnit laddas in typ

    if (!allHasSigned) return;
    //vetta fan varför timeout behöver vara här men verkar som att Canvasen inte hinner ritas klart annars
    setTimeout(() => {
      const me =
        document.querySelector(".canvas-container_me canvas:nth-child(2)") &&
        document
          .querySelector(".canvas-container_me canvas:nth-child(2)")
          .toDataURL();
      const other =
        document.querySelector(".canvas-container_other canvas:nth-child(2)") &&
        document
          .querySelector(".canvas-container_other canvas:nth-child(2)")
          .toDataURL();

      this.setState({
        signaturesDataURL: [me, other]
      });
    });
  };

  freezeSignatureFromScrollingiOS = () => {
    const me = document.querySelector(
      ".canvas-container_me canvas:first-child"
    );
    me.addEventListener("touchstart", function(event) {
      event.preventDefault();
    });
    me.addEventListener("touchmove", function(event) {
      event.preventDefault();
    });
    me.addEventListener("touchend", function(event) {
      event.preventDefault();
    });
    me.addEventListener("touchcancel", function(event) {
      event.preventDefault();
    });
  };

  async componentDidMount() {
    this.getRequestUnlockStatus();
    await this.getSignaturesFromDatabase();
    this.getSignaturesDataURL();
    this.freezeSignatureFromScrollingiOS();
  }

  render() {
    const { intl } = this.props;

    let requestUnlock;
    if (!this.state.requestUnlock) {
      requestUnlock = (
        <button
          className="button button--sign"
          onClick={this.props.requestUnlockAgreement}
        >
          Skicka förfrågan om att låsa upp avtalet
        </button>
      );
    } else if (this.state.requestUnlock == "hasSentRequest") {
      requestUnlock = (
        <p>
          <strong>Request unlock agreement has been sent</strong>
        </p>
      );
    } else if (this.state.requestUnlock == "haveReceivedRequest") {
      requestUnlock = (
        <div>
          <p>
            {this.props.personalData[this.props.otherUser].name} want to unlock
            the agreement to make changes. Do you approve?
          </p>
          <button className="button" onClick={this.props.unlock}>
            Unlock Agreement
          </button>
          <button
            className="button button--warning"
            onClick={this.props.declineUnlock}
          >
            Decline
          </button>
        </div>
      );
    }

    return (
      <div>
        <div className="signature-container">
          <div>
            <CanvasDraw
              className="canvas-container canvas-container_me"
              ref={canvasDraw => (this.saveableCanvas = canvasDraw)}
              brushColor={"black"}
              brushRadius={1}
              hideGrid={true}
              canvasWidth={320}
              canvasHeight={100}
              lazyRadius={0}
              disabled={
                this.state.signatures
                  ? this.state.signatures[this.props.uid]
                  : false
              }
              saveData={
                this.state.signatures && this.state.signatures[this.props.uid]
                  ? this.state.signatures[this.props.uid].signature
                  : ""
              }
              immediateLoading={true}
            />
            <div className="signature">
              <SignatureData
                getTodaysDate={this.props.getTodaysDate}
                personalData={this.props.personalData}
                part={this.props.uid}
                signatures={this.state.signatures}
              />
            </div>
          </div>

          {this.possibleToSign() && (
            <div>
              <button
                className="button button--sign"
                onClick={() => this.saveSignature(intl)}
              >
                <FormattedMessage id="sign" />
              </button>
              <button
                className="button button--sign"
                onClick={() => {
                  this.saveableCanvas.clear();
                }}
              >
                <FormattedMessage id="clear" />
              </button>
            </div>
          )}
        </div>
        <div className="signature-container">
          <div>
            <CanvasDraw
              className="canvas-container canvas-container_other"
              ref={canvasDraw => (this.saveableCanvas2 = canvasDraw)}
              brushColor={"black"}
              brushRadius={1}
              hideGrid={true}
              canvasWidth={320}
              canvasHeight={100}
              lazyRadius={0}
              disabled={true}
              saveData={
                this.state.signatures &&
                this.state.signatures[this.props.otherUser]
                  ? this.state.signatures[this.props.otherUser].signature
                  : ""
              }
              immediateLoading={true}
            />
            <div className="signature">
              <SignatureData
                getTodaysDate={this.props.getTodaysDate}
                personalData={this.props.personalData}
                part={this.props.otherUser}
                signatures={this.state.signatures}
              />
            </div>
          </div>
        </div>
        {this.props.mode === "locked" && // kanske ska vara someoneHasSignedOrLocked istället för mode?
          !this.props.allHasSigned(this.props.agreement) && (
            <div>{requestUnlock}</div>
          )}
        {this.props.allHasSigned(this.props.agreement) &&
          this.state.signaturesDataURL && (
            <PDF
              agreement={this.props.agreement}
              signaturesDataURL={this.state.signaturesDataURL}
              getTodaysDate={this.props.getTodaysDate}
              personalData={this.props.personalData}
              uid={this.props.uid}
              otherUser={this.props.otherUser}
              signatures={this.state.signatures}
            />
          )}
      </div>
    );
  }
}

export default injectIntl(Sign);
