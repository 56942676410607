import React, { Component } from "react";
import ParagraphContent from "./ParagraphContent";
import Textarea from "react-textarea-autosize";

class Paragraph extends Component {
  render() {
    return (
      <div className={!this.props.isVisible ? "hidden" : undefined}>
        <Textarea
          onClick={e => {
            if (!this.props.someoneHasSignedOrLocked) {
              this.props.clickOnParagraph(this.props.paragraphIndex, e);
            }
          }}
          className={
            "paragraph" +
            (this.props.edit ? " hidden" : "") +
            (this.props.someoneHasSignedOrLocked
              ? " paragraph--signed-or-locked"
              : "")
          }
          value={`§${this.props.chapterIndex + 1}.${this.props.paragraphIndex +
            1} ${this.props.value}`}
          readOnly
        />

        {this.props.edit && (
          <ParagraphContent
            key={this.props.paragraphDatabaseId}
            shouldEdit={this.props.edit}
            value={this.props.value}
            handleChange={this.props.handleChange}
            handleSubmit={this.props.handleSubmit}
            onRemoveParagraph={this.props.onRemoveParagraph}
            paragraphIndex={this.props.paragraphIndex}
            chapterIndex={this.props.chapterIndex}
            users={this.props.users}
            uid={this.props.uid}
            agreementId={this.props.agreementId}
            chapterDatabaseId={this.props.chapterDatabaseId}
            paragraphDatabaseId={this.props.paragraphDatabaseId}
            displayName={this.props.displayName}
            otherUser={this.props.otherUser}
            saveParagraphContent={this.props.saveParagraphContent}
          />
        )}
      </div>
    );
  }
}

export default Paragraph;
