import React, { Component } from "react";
import { database } from "./Firebase";
import BackButton from "./BackButton";
import { FormattedMessage, injectIntl } from "react-intl";

class MyAccount extends Component {
  state = {
    name: "",
    personalNumber: ""
  };

  updateUserToDatabase = () => {
    database
      .ref(`users/${this.props.user.uid}`)
      .update(this.state)
      .then(
        window.alert(this.props.intl.formatMessage({ id: "info-updated" }))
      );
  };

  handleSubmit = e => {
    e.preventDefault();
    this.updateUserToDatabase(e);
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  readPersonalDataFromDatabase = () => {
    database
      .ref("users/" + this.props.user.uid)
      .once("value")
      .then(snapshot => {
        this.setState({
          name: snapshot.val().name
            ? snapshot.val().name
            : this.props.displayName,
          personalNumber: snapshot.val().personalNumber
            ? snapshot.val().personalNumber
            : ""
        });
      });
  };

  deleteAccount = async () => {
    const deleteOk = window.confirm(
      "Are you sure you want to delete your account? This action will permanently delete your data, excluding the contracts which are shared with someone else."
    );

    if (deleteOk) {
      await this.deleteUserFromAgreements();
      await this.deleteUserFromUsers();
      this.props.logout();
    }
  };

  deleteUserFromUsers = () => {
    database.ref("users/" + this.props.user.uid).remove();
  };

  deleteUserFromAgreements = () => {
    database
      .ref("agreementList/")
      .orderByChild(`users/${this.props.uid}/email`)
      .equalTo(this.props.user.email)
      .once("value")
      .then(snapshot => {
        snapshot.forEach(agreement => {
          const notAlone =
            agreement.val().users &&
            Object.keys(agreement.val().users).length > 1 &&
            Object.keys(agreement.val().users).includes(this.props.uid);

          if (notAlone) {
            if (!this.props.allHasSigned(agreement.val())) {
              //Om ej är ensam men all har inte signat,

              //Ta bort användaren från avtalet och ta bort hasSigned och Lock (om de finns)
              database
                .ref(
                  "agreementList/" + agreement.key + "/users/" + this.props.uid
                )
                .remove();

              database.ref("agreementList/" + agreement.key + "/lock").remove();

              database
                .ref("agreementList/" + agreement.key + "/hasSigned")
                .remove();

              //och gå igenom varenda paragraph och ta bort alla (eventuella) toggles...
              database
                .ref("agreementList/" + agreement.key + "/chapter")
                .once("value", chapters => {
                  chapters.forEach(chapter => {
                    chapter.child("paragraph").forEach(paragraph => {
                      database
                        .ref(
                          "agreementList/" +
                            agreement.key +
                            "/chapter/" +
                            chapter.key +
                            "/paragraph/" +
                            paragraph.key +
                            "/uid"
                        )
                        .remove();
                    });
                  });
                });
            }
          } else if (
            agreement.val().users &&
            Object.keys(agreement.val().users).length == 1 &&
            Object.keys(agreement.val().users).includes(this.props.uid)
          ) {
            this.props.deleteAgreement(agreement.key);
          }
        });
      });
  };

  componentDidMount() {
    this.readPersonalDataFromDatabase();
  }

  render() {
    const { intl } = this.props;

    return (
      <div className="background screen-holder">
        <div className="top-navbar">
          <BackButton />
          <h2 className="top-navbar__title">
            <FormattedMessage id="my-account" />
          </h2>
        </div>
        <div className="my-account">
          <p>
            <FormattedMessage id="my-account-paragraph" />
          </p>
          <form onSubmit={this.handleSubmit} className="personal-data">
            <input
              className="personal-data__input"
              name="name"
              type="text"
              placeholder={intl.formatMessage({ id: "your-name" })}
              onChange={this.handleChange}
              value={this.state.name}
            />
            <input
              className="personal-data__input"
              name="personalNumber"
              type="text"
              placeholder={intl.formatMessage({ id: "personal-number" })}
              onChange={this.handleChange}
              value={this.state.personalNumber}
            />
            <input
              className="button"
              type="submit"
              value={intl.formatMessage({ id: "save" })}
            />
          </form>
          <button
            className="button button--log-out"
            onClick={this.props.logout}
          >
            <FormattedMessage id="log-out" />
          </button>
          <button
            className="button button--warning button--delete-account"
            onClick={this.deleteAccount}
          >
            <FormattedMessage id="delete-account" />
          </button>
        </div>
      </div>
    );
  }
}
export default injectIntl(MyAccount);
