import React, { Component } from "react";
import { withRouter } from "react-router-dom";

class BackButton extends Component {
  render() {
    const { history } = this.props;

    return (
      <>
        <button onClick={history.goBack} className="back">
          <i className="fas fa-arrow-left fa-lg" />
        </button>
      </>
    );
  }
}

export default withRouter(BackButton);
