import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";

class CreateAgreement extends Component {
  state = {
    title: "",
    users: {}
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.createAgreement(this.state);
    this.setState({
      title: "",
      users: {}
    });
    this.props.history.push("/home");
  };

  setUser = async () => {
    const users = await this.props.getUser();
    this.setState({ users });
  };

  componentWillMount() {
    this.setUser();
  }

  render() {
    const { intl } = this.props;

    return (
      <div>
        <form className="create-agreement" onSubmit={this.handleSubmit}>
          <input
            name="title"
            type="text"
            placeholder={intl.formatMessage({ id: "title" })}
            onChange={this.handleChange}
            value={this.state.title}
            autoFocus
          />
          <input
            className="button button--create-agreement"
            type="submit"
            value={intl.formatMessage({ id: "create-agreement" })}
          />
        </form>
      </div>
    );
  }
}

export default withRouter(injectIntl(CreateAgreement));
