import React, { Component } from "react";

class Title extends Component {
  render() {
    return (
      <div className="logo-container">
        <h1 className="logo-container__heading">
          <i>Splink</i>
        </h1>
      </div>
    );
  }
}

export default Title;
