import React from "react";
import TemplateListItem from "./TemplateListItem";
import BackButton from "./BackButton";
import CreateAgreement from "./CreateAgreement";
import { FormattedMessage } from "react-intl";
import { Route, Link } from "react-router-dom";

class Templates extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      templatelist: []
    };
  }

  render() {
    return (
      <div className="background screen-holder">
        <div className="top-navbar">
          <BackButton />
          <h2 className="top-navbar__title">
            <FormattedMessage id="templates" />
          </h2>
        </div>
        <div>
          {this.props.templateList.map((p, i) => (
            <TemplateListItem
              title={this.props.templateList[i].title}
              key={this.props.templateList[i].agreementId}
              onClick={() => {
                this.props.renderTemplate(i);
              }}
            />
          ))}
          <Link to="/create-agreement/create-new">
            <button className="agreement-list__item">
              <svg
                className="pen"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path d="M64 368v80h80l235.727-235.729-79.999-79.998L64 368zm377.602-217.602c8.531-8.531 8.531-21.334 0-29.865l-50.135-50.135c-8.531-8.531-21.334-8.531-29.865 0l-39.468 39.469 79.999 79.998 39.469-39.467z" />
              </svg>
              <FormattedMessage id="create-agreement" />
            </button>
          </Link>
          <Route
            path="/create-agreement/create-new"
            render={() => (
              <div>
                <CreateAgreement
                  createAgreement={this.props.createAgreement}
                  uid={this.props.uid}
                  getUser={this.props.getUser}
                />
              </div>
            )}
          />
        </div>
      </div>
    );
  }
}

export default Templates;
