import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

export default class TopNavbar extends Component {
  render() {
    return (
      <div>
        <Link to="/account">
          <button className="button">
            <FormattedMessage id="my-account" />
          </button>
        </Link>
      </div>
    );
  }
}
